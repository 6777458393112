import request from '@/utils/request'


// 查询群组列表
export function listGroup(query) {
  return request({
    url: '/user/group/list',
    method: 'get',
    params: query
  })
}

// 查询群组分页
export function pageGroup(query) {
  return request({
    url: '/user/group/page',
    method: 'get',
    params: query
  })
}

// 查询群组详细
export function getGroup(data) {
  return request({
    url: '/user/group/detail',
    method: 'get',
    params: data
  })
}

// 新增群组
export function addGroup(data) {
  return request({
    url: '/user/group/add',
    method: 'post',
    data: data
  })
}

// 修改群组
export function updateGroup(data) {
  return request({
    url: '/user/group/edit',
    method: 'post',
    data: data
  })
}

// 删除群组
export function delGroup(data) {
  return request({
    url: '/user/group/delete',
    method: 'post',
    data: data
  })
}
