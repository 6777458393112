<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="群名称" prop="groupName" >
        <a-input v-model="form.groupName" placeholder="请输入群名称" />
      </a-form-model-item>
      <a-form-model-item label="群暗号" prop="groupCypher" >
        <a-input v-model="form.groupCypher" placeholder="请输入群暗号" />
      </a-form-model-item>
      <a-form-model-item label="群头像" prop="groupAvatar" >
        <a-input v-model="form.groupAvatar" placeholder="请输入群头像" />
      </a-form-model-item>
      <a-form-model-item label="简介" prop="about" >
        <a-input v-model="form.about" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="im群id" prop="imGroupId" >
        <a-input v-model="form.imGroupId" placeholder="请输入im群id" />
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="startTime" >
        <a-date-picker style="width: 100%" v-model="form.startTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="是否暂停 0-否 1-是" prop="isPause" >
        <a-input v-model="form.isPause" placeholder="请输入是否暂停 0-否 1-是" />
      </a-form-model-item>
      <a-form-model-item label="暂停时间" prop="pauseTime" >
        <a-date-picker style="width: 100%" v-model="form.pauseTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="用时" prop="takeTime" >
        <a-input v-model="form.takeTime" placeholder="请输入用时" />
      </a-form-model-item>
      <a-form-model-item label="状态 0-进行中 1-已结束" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGroup, addGroup, updateGroup } from '@/api/user/group'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        groupName: null,

        groupCypher: null,

        groupAvatar: null,

        about: null,

        imGroupId: null,

        startTime: null,

        isPause: null,

        pauseTime: null,

        takeTime: null,

        status: 0,

        createTime: null,

        remark: null,

        sort: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        sort: [
          { required: true, message: '排序不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        groupName: null,
        groupCypher: null,
        groupAvatar: null,
        about: null,
        imGroupId: null,
        startTime: null,
        isPause: null,
        pauseTime: null,
        takeTime: null,
        status: 0,
        createTime: null,
        remark: null,
        sort: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGroup({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGroup(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGroup(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
